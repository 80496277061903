<template>
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_1295_6426)">
      <path
        d="M1.4359 0.538332C1.1449 0.846332 0.9729 1.32433 0.9729 1.94333V24.0593C0.9729 24.6793 1.1449 25.1563 1.4359 25.4643L1.5099 25.5363L13.8989 13.1473V13.0013V12.8553L1.5099 0.465332L1.4359 0.538332Z"
        fill="url(#paint0_linear_1295_6426)"
      />
      <path
        d="M18.0279 17.2781L13.8989 13.1471V13.0011V12.8551L18.0289 8.7251L18.1219 8.7781L23.0149 11.5581C24.4119 12.3521 24.4119 13.6511 23.0149 14.4461L18.1219 17.2261L18.0279 17.2781Z"
        fill="url(#paint1_linear_1295_6426)"
      />
      <g filter="url(#filter1_i_1295_6426)">
        <path
          d="M18.1218 17.225L13.8978 13.001L1.43579 25.464C1.89579 25.952 2.65679 26.012 3.51379 25.526L18.1218 17.225Z"
          fill="url(#paint2_linear_1295_6426)"
        />
      </g>
      <path
        d="M18.1218 8.77719L3.51379 0.477188C2.65679 -0.00981149 1.89579 0.0511888 1.43579 0.539189L13.8988 13.0022L18.1218 8.77719Z"
        fill="url(#paint3_linear_1295_6426)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_1295_6426"
        x="0.9729"
        y="0.141113"
        width="23.0898"
        height="25.7207"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1295_6426" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_1295_6426"
          result="effect2_innerShadow_1295_6426"
        />
      </filter>
      <filter
        id="filter1_i_1295_6426"
        x="1.43579"
        y="13.001"
        width="16.686"
        height="12.8608"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1295_6426" />
      </filter>
      <linearGradient
        id="paint0_linear_1295_6426"
        x1="12.8007"
        y1="1.70933"
        x2="-3.9813"
        y2="18.4913"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A0FF" />
        <stop offset="0.0066" stop-color="#00A1FF" />
        <stop offset="0.2601" stop-color="#00BEFF" />
        <stop offset="0.5122" stop-color="#00D2FF" />
        <stop offset="0.7604" stop-color="#00DFFF" />
        <stop offset="1" stop-color="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1295_6426"
        x1="24.8334"
        y1="13.0011"
        x2="0.637527"
        y2="13.0011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE000" />
        <stop offset="0.4087" stop-color="#FFBD00" />
        <stop offset="0.7754" stop-color="#FFA500" />
        <stop offset="1" stop-color="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1295_6426"
        x1="15.8279"
        y1="15.2949"
        x2="-6.93061"
        y2="38.0534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3A44" />
        <stop offset="1" stop-color="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1295_6426"
        x1="-1.70281"
        y1="-6.82301"
        x2="8.45949"
        y2="3.33929"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#32A071" />
        <stop offset="0.0685" stop-color="#2DA771" />
        <stop offset="0.4762" stop-color="#15CF74" />
        <stop offset="0.8009" stop-color="#06E775" />
        <stop offset="1" stop-color="#00F076" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts" setup></script>

<style></style>
