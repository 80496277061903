<template>
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9921 4.15073C16.9399 3.05225 17.5788 1.52227 17.4039 0C16.0381 0.052 14.3857 0.872491 13.4064 1.96968C12.5269 2.94336 11.7592 4.49917 11.9656 5.99155C13.4892 6.10464 15.0443 5.25051 15.9921 4.15073ZM19.4086 13.8125C19.4468 17.7475 23.0105 19.0565 23.0499 19.0734C23.021 19.1657 22.4807 20.9386 21.1728 22.7716C20.0409 24.355 18.867 25.9319 17.0175 25.9657C15.2007 25.9982 14.6158 24.9336 12.5375 24.9336C10.4605 24.9336 9.81107 25.9318 8.09163 25.9981C6.30646 26.0618 4.9459 24.285 3.80618 22.7068C1.47416 19.479 -0.307064 13.5851 2.08543 9.60718C3.27378 7.6325 5.39679 6.38013 7.70252 6.34893C9.45482 6.31643 11.1098 7.47881 12.1812 7.47881C13.2526 7.47881 15.2638 6.08132 17.3776 6.28672C18.2623 6.32182 20.7468 6.62851 22.3414 8.86577C22.2126 8.94247 19.3771 10.5236 19.4086 13.8125Z"
      fill="#201A1E"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style></style>
